<template>
  <div class="faq">
    <FixedNavbar />
    <div class="su_page_content">
      <TitleBanner v-bind:PageTitle="BannerTitle" />
      <FaqAccordian />
      <Resources v-bind:ResourceType="ResourceType" />
      <QuoteCta v-if="widgetCustomHTML.length>0" v-bind:CustomWidget="widgetCustomHTML" />
    </div>
    <Footer />
  </div>
</template>

<script>
import FixedNavbar from "@/components/Common/Navbars/FixedNavbar.vue";
import TitleBanner from "@/components/Common/TitleBanner.vue";
import FaqAccordian from "@/components/Faq/FaqAccordian.vue";
import Resources from "@/components/Common/Resources/Resources.vue";
import QuoteCta from "@/components/Common/QuoteCta.vue";
import Footer from "@/components/Common/Footer.vue";
import axios from "axios";
export default {
  name: "Faq",
  components: {
    FixedNavbar,
    TitleBanner,
    FaqAccordian,
    Resources,
    QuoteCta,
    Footer
  },
  data() {
    return {
      ResourceType: "caseStudy",
      BannerTitle: [],
      widgetCustomHTML: []
    };
  },
  mounted() {
    this.getBanner();
    this.getQuote();
    this.setMetaInfo(297)
  },
  methods: {
    getBanner() {
      this.loader = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "header_banner",
            taxonomy: "banner_type",
            cat_name: "faq-page-banner"
          }
        })
        .then(response => {
          this.loader.hide();
          this.BannerTitle = response.data.customPostList;
          console.log("BannerTitle==", this.BannerTitle);
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    getQuote() {
      this.loader1 = this.showSpinner();
      axios
        .get(`${process.env.VUE_APP_API}/wp/v1/custom_widget`, {
          params: {
            type: "widget_custom_html"
          }
        })
        .then(response => {
          this.loader1.hide();
          this.widgetCustomHTML = response.data.customWidgetList;
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  }
};
</script>




