<template>
  <div class="faq-accordian-container spacer-80">
    <div class="container">
      <div class="panel-group" id="accordion">
        <div class="panel panel-default" v-for="(faq, idf) in faqs" :key="idf">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion" v-bind:href="'#collapse'+idf" v-html="faq.title"></a>
            </h4>
          </div>
          <div v-bind:id="'collapse'+idf" class="panel-collapse collapse in">
            <div class="panel-body" v-html="faq.content"></div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default { 
  components: {},
  data() {
    return {
      faqs: []      
    };
  },
  mounted() {
      this.loader = this.showSpinner();
      axios.post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
              params: {
                  post_type: 'faq'
              }
          })
          .then(response => { this.loader.hide();
              this.faqs = response.data.customPostList;
          })
          .catch(error => {
              console.log("error", error)
          });
  }
};
</script>